import type { loader as RootLoader } from '~/root'

import {
  ArrowRightEndOnRectangleIcon,
  ArrowRightStartOnRectangleIcon,
  Bars3Icon,
  CreditCardIcon,
  NewspaperIcon,
  QuestionMarkCircleIcon,
  ReceiptPercentIcon,
  UserIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '~/components/ui/dropdown'
import { Navbar, NavbarItem, NavbarSection } from '~/components/ui/navbar'

import {
  Link,
  type LinkProps,
  isRouteErrorResponse,
  useLocation,
  useParams,
  useRouteError,
  useRouteLoaderData,
} from 'react-router'

import { EditIcon, RefreshCwIcon } from 'lucide-react'
import type { SetOptional } from 'type-fest'
import { LoungePairPlusLogo } from '~/components/loungepair-plus/Logo'
import PayRewardsLogo from '~/components/svg-logos/PayRewardsLogo'
import { shouldHideFeature } from '~/config/features'
import type { BasicAccountWithRoles } from '~/models/accounts.server'
import {
  isHomeLinkTenantSettings,
  isLoungePairTenantSettings,
  isPayRewardsTenantSettings,
  isPointHacksTenantSettings,
} from '~/models/tenants/guards'
import * as accountUtils from '~/utils/accounts'
import { cn, getSupportUrl } from '~/utils/utils'
import { LoungePairIcon } from './Icon'
import { SearchAutocomplete } from './SearchAutocomplete'
import LoungeIcon from './icons/LoungeIcon'
import PointHacksLogo from './svg-logos/PointHacksLogo'
import { Button } from './ui/button'

export function Header() {
  if (shouldHideFeature('nav')) return false
  const error = useRouteError()
  const root = useRouteLoaderData<typeof RootLoader>('root')
  const notLoggedIn = isRouteErrorResponse(error) || Boolean(!root?.account)
  const isSubscribed = root?.hasActiveSubscription ?? false
  const supportUrl = getSupportUrl()

  return (
    <div className="px-6 bg-white sm:border-b border-midnight-400">
      <Navbar className="flex justify-between max-w-[1201px] text-midnight-900 mx-auto">
        <div className="flex-1 gap-6 flex items-center justify-center lg:justify-end w-full">
          <NavbarLogo />
          <NavbarSection className="flex-1 max-w-[534px] min-h-12">
            <SearchAutocomplete />
          </NavbarSection>
        </div>
        <NavbarSection className="justify-end">
          <>
            <NavbarItem className="hidden lg:block" href="/directory">
              Lounges
            </NavbarItem>
            {isSubscribed ? (
              <NavbarItem
                className="hidden lg:block"
                target="_blank"
                href="https://www.loungepair.com/blog"
              >
                Blog
              </NavbarItem>
            ) : (
              <NavbarItem className="hidden lg:block" href="/plus/">
                LoungePair
                <span className="text-blue-500 font-semibold -m-2.5 pr-2">
                  +
                </span>
              </NavbarItem>
            )}

            <NavbarItem
              target="_blank"
              className="hidden lg:block"
              href={supportUrl}
            >
              Support
            </NavbarItem>
          </>
          {notLoggedIn ? (
            <>
              <NavbarItem className="hidden lg:block" href="/account/login/">
                Login
              </NavbarItem>
              {root?.tenant.features.accountCreate && (
                <Button
                  className="hidden lg:block !px-4"
                  href="/account/signup/"
                >
                  Sign up
                </Button>
              )}
            </>
          ) : null}

          <AccountMenu
            isSubscribed={isSubscribed}
            notLoggedIn={notLoggedIn}
            account={root?.account}
          />
        </NavbarSection>
      </Navbar>
    </div>
  )
}

export function AccountMenu({
  notLoggedIn,
  isSubscribed,
  account,
}: {
  notLoggedIn: boolean
  isSubscribed: boolean
  account?: BasicAccountWithRoles | null
}) {
  const params = useParams()
  const root = useRouteLoaderData<typeof RootLoader>('root')
  const currentUrl = useLocation()
  const supportUrl = getSupportUrl()
  const canEdit = accountUtils.hasRole(root?.account, 'LP_ADMIN')

  // These have been implemented
  const shouldEdit = params.iata || params.slug
  function handleEdit(): string {
    if ('slug' in params) {
      return `/admin/resources/lounges?page=1&filters.slug=${params.slug}`
    }
    if ('iata' in params) {
      // edit airport
      return `/admin/resources/airports?page=1&filters.iata=${params.iata}`
    }
    if ('id' in params) {
      // edit order
    }
    return '/admin'
  }

  if (notLoggedIn) {
    return (
      <Dropdown>
        <DropdownButton
          as={NavbarItem}
          aria-label="Navigation"
          className="lg:hidden"
        >
          <div className="border rounded-full border-midnight-400">
            <Bars3Icon className="inline-block w-6 h-6 p-1 text-midnight-900" />
          </div>
        </DropdownButton>
        <DropdownMenu className="min-w-64 z-10" anchor="bottom end">
          <DropdownItem href="/account/login">
            <ArrowRightEndOnRectangleIcon />
            <DropdownLabel>Account Login</DropdownLabel>
          </DropdownItem>
          {root?.tenant.features.accountCreate && (
            <DropdownItem href="/account/signup">
              <UserPlusIcon />
              <DropdownLabel>Create Account</DropdownLabel>
            </DropdownItem>
          )}

          <DropdownDivider />

          <DropdownItem rel="nofollow" href="/directory">
            <LoungeIcon className="w-4" />
            <DropdownLabel>Lounges</DropdownLabel>
          </DropdownItem>

          <DropdownItem href="/plus/" className="group">
            <ReceiptPercentIcon className="w-5" />
            <DropdownLabel>
              LoungePair
              <span className="text-blue-500 ml-0.5 group-hover:text-white font-semibold">
                +
              </span>
            </DropdownLabel>
          </DropdownItem>

          <DropdownItem rel="nofollow" href={supportUrl}>
            <QuestionMarkCircleIcon />
            <DropdownLabel>Support</DropdownLabel>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }

  // Logged in menu
  return (
    <Dropdown>
      <DropdownButton as={NavbarItem} aria-label="Navigation">
        <div className="border rounded-full border-midnight-400">
          <Bars3Icon className="inline-block w-6 h-6 p-1 text-midnight-900" />
        </div>
      </DropdownButton>
      <DropdownMenu className="min-w-64 z-10" anchor="bottom end">
        <DropdownItem href="/account/orders">
          <UserIcon />
          <DropdownLabel>
            My LoungePair {account?.email ? `(${account?.email})` : ''}
          </DropdownLabel>
        </DropdownItem>
        {isSubscribed ? (
          <DropdownItem href="/account/billing">
            <CreditCardIcon />
            <DropdownLabel>Manage Subscription</DropdownLabel>
          </DropdownItem>
        ) : (
          <DropdownItem href="/plus/" className="group">
            <ReceiptPercentIcon className="w-5" />
            <DropdownLabel>
              Join LoungePair
              <span className="text-blue-500 ml-0.5 group-hover:text-white font-semibold">
                +
              </span>
            </DropdownLabel>
          </DropdownItem>
        )}

        <DropdownDivider />

        <DropdownItem rel="nofollow" href="/directory/">
          <LoungeIcon className="w-4 -ml-0.5" />
          <DropdownLabel>Lounges</DropdownLabel>
        </DropdownItem>

        <DropdownItem target="_blank" href="https://www.loungepair.com/blog">
          <NewspaperIcon />
          <DropdownLabel>Blog</DropdownLabel>
        </DropdownItem>

        <DropdownItem rel="nofollow" href={supportUrl}>
          <QuestionMarkCircleIcon />
          <DropdownLabel>Support</DropdownLabel>
        </DropdownItem>

        <DropdownDivider />

        {canEdit && shouldEdit ? (
          <>
            <DropdownItem
              target="_blank"
              href={handleEdit()}
              aria-label="Edit Page"
            >
              <EditIcon className="w-4 -ml-0.5" />
              <DropdownLabel>Edit Page</DropdownLabel>
            </DropdownItem>
            <DropdownItem
              href={`/api/admin/clear-cache?redirectTo=${currentUrl.pathname}`}
              aria-label="Clear Cache"
            >
              <RefreshCwIcon className="w-4 -ml-0.5" />
              <DropdownLabel>Clear Cache</DropdownLabel>
            </DropdownItem>
          </>
        ) : null}

        <DropdownItem
          href={`/account/logout?redirectTo=${currentUrl.pathname}`}
          reloadDocument={true}
        >
          <ArrowRightStartOnRectangleIcon />
          <DropdownLabel>Log out</DropdownLabel>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

type NavbarLogoProps = SetOptional<LinkProps, 'to'> & {
  imgClassName?: string
}

export function NavbarLogo({
  className,
  imgClassName,
  to,
  ...props
}: NavbarLogoProps) {
  const root = useRouteLoaderData<typeof RootLoader>('root')
  if (!root) return null

  switch (root.tenant.name) {
    case 'loungepair': {
      return isLoungePairTenantSettings(root.tenant.settings) ? (
        <Link
          to="/directory/"
          aria-label="Home"
          className={className}
          {...props}
        >
          <LoungePairIcon className={cn('size-7 sm:hidden', imgClassName)} />
          <LoungePairPlusLogo
            excludePlus={!root.hasActiveSubscription}
            className={cn('hidden sm:block !w-[156px]', imgClassName)}
          />
        </Link>
      ) : null
    }
    case 'payrewards': {
      return isPayRewardsTenantSettings(root.tenant.settings) ? (
        <Link
          to="/directory/"
          aria-label="Home"
          className={className}
          {...props}
        >
          <LoungePairIcon className={cn('size-7 sm:hidden', imgClassName)} />
          <PayRewardsLogo
            className={cn('hidden sm:block !w-[156px]', imgClassName)}
          />
        </Link>
      ) : null
    }
    case 'pointhacks': {
      return isPointHacksTenantSettings(root.tenant.settings) ? (
        <Link
          to="/directory/"
          aria-label="Home"
          className={className}
          {...props}
        >
          <LoungePairIcon className={cn('size-7 sm:hidden', imgClassName)} />
          <PointHacksLogo
            excludePlane
            className={cn(
              'hidden sm:block !w-[156px] text-[#333]',
              imgClassName,
            )}
          />
        </Link>
      ) : null
    }
    case 'homelink': {
      return isHomeLinkTenantSettings(root.tenant.settings) ? (
        <Link
          to="/directory/"
          aria-label="Home"
          className={className}
          {...props}
        >
          <LoungePairIcon className={cn('size-7 sm:hidden', imgClassName)} />
          <img
            src={root.tenant.settings.logo}
            alt="HomeLink Logo"
            className={cn('hidden sm:block h-8', imgClassName)}
          />
        </Link>
      ) : null
    }
    default: {
      const _exhaustiveCheck: never = root.tenant.name
      return null
    }
  }
}
